import en from './en.lang.json';
import de from './de.lang.json';
import fr from './fr.lang.json';

const langs = {
  en: {
    translation: en
  },
  de: {
    translation: de
  },
  fr: {
    translation: fr
  },
};

export default langs;