export default {
  tac: `
  <style>
    h2 {
      margin-block-start: 2em;
    }
    .tac-content a {
      color: #e9425e;
      margin-top: 30px;
    }
    .tac-content div {
      margin-bottom: 10px;
      margin-top: 5px;
    }
    .tac-content ol {
      list-style-position: inside;
      padding-inline-start: unset;
      counter-reset: item;
      margin-block-start: 5px;
      text-align: justify;
    }
    .tac-content {
      font-size: 0.8rem;
    }
    .tac-content .title {
      font-size: 1.1rem;
      font-weight: bold;
    }
    .tac-content ol.a > li::marker {
      font-size: 1.1rem;
      font-weight: bold;
    }
    .tac-content .last-update {
      font-size: 1rem;
      text-align: left;
      margin-top: 30px;
    }
  </style>
  <h2>Acuerdo de usuario de PXL Vision AG para los usuarios finales de la WebApp "Daego</h2>
  <div class="tac-content">
    <ol class="a">
      <li>
        <span class="title">Descripción del servicio</span>
        <div>
          PXL Vision AG (en adelante, "PXL", "nosotros" o "nuestro") le proporciona la WebApp "Daego" (en adelante, el "Producto"), una solución de portal para utilizar en su dispositivo terminal compatible, así como servicios de servidor a través de los cuales puede escanear su documento de identidad emitido por una autoridad oficial (por ejemplo, pasaporte, tarjeta de identidad, tarjeta de identidad de extranjero, etc.). Si el escaneo es exitoso, se genera una prueba digital de identidad para ser utilizada por los socios de la transacción.
        </div>
        <div>
          La prueba de identidad digital le permite llevar a cabo una transacción con los proveedores correspondientes (por ejemplo, proveedores de telefonía móvil, bancos, tiendas web, etc. - en lo sucesivo denominados "socios de la transacción").
        </div>
        <div>
          Para llevar a cabo una transacción, un socio de la transacción le pedirá (por ejemplo, al celebrar un contrato en línea) que haga clic en un enlace a la aplicación web proporcionada por el socio con un código de transacción o que, tras introducir directamente la dirección web de la aplicación web y escanear los documentos descritos a continuación, introduzca un código de transacción proporcionado por el socio o escanee un código QR.
        </div>
        <div>
          La WebApp le identifica como titular del documento de identificación oficial escaneado durante la transacción. Para la verificación por parte del socio de la transacción, los datos recogidos también se transmiten al socio de la transacción cuando ésta se inicia, quien los almacena de acuerdo con sus normas de protección de datos.
        </div>
        <div>
          La verificación de su documento de identidad con el selfie tomado se realiza de la siguiente manera:
        </div>
        <div>
          Al enfocar el documento de identidad con la cámara del dispositivo final y activarlo, la aplicación web fotografía y escanea su documento de identidad e identifica los datos personales que contiene mediante el reconocimiento de texto.
        </div>
        <div>
          Además, se captura la foto completa del documento de identidad con los elementos de seguridad adicionales (como el holograma y los elementos de diseño).
        </div>
        <div>
          Después de escanear el documento, se le pedirá que grabe un breve vídeo suyo a través de la cámara conectada al dispositivo del terminal.
        </div>
        <div>
          Los datos, fotos y vídeos recogidos por la WebApp se transmiten desde su dispositivo terminal a través de una conexión segura en tiempo real a los servidores con certificación SOC 2 situados en Suiza, donde se procesan y almacenan. En los sistemas de PXL, los datos recogidos se comparan entre sí y la foto recogida se coteja con el vídeo selfie en relación con los datos biométricos. Además, se verifica la foto del documento de identidad. Una vez finalizada la verificación, se le solicita su consentimiento para la transmisión de los datos de identificación y los archivos de imagen recopilados al socio de la transacción y, si da su consentimiento, los datos se transmiten al socio de la transacción que le proporcionó el enlace a la WebApp o la identificación de la transacción o el código de barras para llevar a cabo el proceso de identificación, para la confirmación de la identidad y, si procede, para el almacenamiento en su cuenta de cliente allí.
        </div>
        <div>
          El uso contractual de la WebApp y el proceso de identificación son gratuitos para usted.
        </div>
        <div>
          El uso de la WebApp en un teléfono móvil puede dar lugar a conexiones de datos de telefonía móvil, cuyo cálculo o volumen de cobro dependerá de la tarifa de datos de su operador de red.
        </div>
      </li>
      <li>
        <span class="title">Requisitos de uso</span>
        <div>
          El requisito previo para utilizar la funcionalidad de identificación y verificación de la WebApp es el suministro de un código de transacción o un enlace o código QR por parte de un socio de la transacción.
        </div>
        <div>
          La WebApp no está vinculada a la creación de una cuenta de usuario permanente con PXL, la verificación sólo se realiza una vez para el código de transacción asignado. Si se identifica de nuevo para otro servicio a través de la WebApp, el proceso de escaneo y verificación debe repetirse.
        </div>
        <div>
          A menos que usted permita explícitamente que PXL utilice los datos de identificación recogidos con el fin de desarrollar y mejorar científicamente los procesos de aprendizaje automático subyacentes al procedimiento de identificación, sus datos de identificación se eliminarán después de la transmisión al socio de la transacción. El socio de la transacción almacena estos datos de acuerdo con sus directrices de protección de datos, de las que debe tomar nota por separado.
        </div>
        <div>
          Si no está totalmente de acuerdo con estas condiciones contractuales, no continúe con el proceso de identificación y pregunte al socio de la transacción por otras opciones de identificación.
        </div>
      </li>
      <li>
        <span class="title">Derechos del vídeo selfie</span>
        <div>
          Para utilizar el Producto, debe proporcionar a PXL sus datos personales y el vídeo selfie que ha creado. Por la presente, usted concede a PXL el derecho a utilizar el vídeo que ha creado, de forma gratuita y por un período ilimitado de tiempo y lugar, con el fin de crear y mantener la identidad digital, para llevar a cabo el proceso de verificación y para mejorar los procesos técnicos (siempre que haya dado su consentimiento) y renunciar a sus derechos sobre su propia imagen en el ámbito del procesamiento necesario de los datos de la imagen. Puede revocar este consentimiento en cualquier momento enviando un correo electrónico a
          <a href="mailto:privacy@pxl-vision.com">privacy@pxl-vision.com</a>.
        </div>
      </li>
      <li>
        <span class="title">Renuncia a la garantía</span>
        <div>
          La aplicación web y los servicios relacionados que se ofrecen de forma gratuita se proporcionan "tal cual", es decir, con todos los defectos, si los hubiera, y excluyendo cualquier representación o garantía de cualquier tipo. PXL por la presente renuncia a todas las garantías.
        </div>
        <div>
          La compatibilidad con los sistemas operativos y dispositivos finales utilizados por el usuario no está garantizada en ningún momento.
        </div>
        <div>
          Por lo tanto, el uso de la WebApp para verificar la identidad es por cuenta y riesgo del usuario, y tampoco se garantiza el éxito del uso de la App para iniciar una transacción a través de la misma.
        </div>
      </li>
      <li>
        <span class="title">Responsabilidad de PXL</span>
        <div>
          PXL sólo es responsable de los daños si estos se basan en el incumplimiento de una obligación contractual material o en el comportamiento intencional o gravemente negligente de PXL, sus representantes legales o agentes indirectos. Si se incumple una obligación contractual esencial por negligencia leve, la responsabilidad de PXL se limita a los daños previsibles típicos del contrato. Una obligación contractual esencial se da en el caso de obligaciones cuyo cumplimiento hace posible la correcta ejecución del contrato o en cuya observancia ha confiado y podría haber confiado el cliente.
        </div>
        <div>
          Queda excluida cualquier otra responsabilidad, incluida la responsabilidad por daños indirectos, daños consecuenciales y pérdida de beneficios.
        </div>
      </li>
      <li>
        <span class="title">Protección de datos</span>
        <div>
          PXL se compromete a proteger su privacidad. Nuestro objetivo es hacer que el uso de nuestros productos y servicios sea tan fácil de usar, fiable y seguro como sea posible, protegiendo siempre su información personal como se define en la Política de Privacidad de PXL ("Política de Privacidad"). Para obtener información sobre nuestras prácticas de privacidad y nuestra política de privacidad, consulte la
          <u>Política de Privacidad</u>.
        </div>
        <div>
          Antes de utilizar el proceso de verificación, le pedimos su consentimiento explícito para que PXL pueda recopilar y procesar datos personales, incluidas categorías especiales de datos personales en el sentido del artículo 9 (1) del Reglamento General de Protección de Datos o del artículo 3 (c) de la Ley de Protección de Datos de Suiza, a través de la WebApp, como los datos biométricos.
        </div>
        <div>
          También recopilamos datos sobre documentos de identificación que la aplicación no pudo leer, fotos y vídeos en los que la verificación humana y la aplicación proporcionan resultados diferentes, y datos sobre verificaciones satisfactorias.
        </div>
        <div>
          Para más información, lea la Política de Privacidad, que por la presente forma parte de su Acuerdo de Usuario.
        </div>
        <div>
          Tenga en cuenta que se aplican políticas de privacidad distintas a las descargas del Software a través de las App Stores (por ejemplo, Apple y Google), que debe conocer al utilizar la App Store. PXL no acepta ninguna responsabilidad por el procesamiento de datos de estos proveedores.
        </div>
      </li>
      <li>
        <span class="title">Otras disposiciones.</span>
        <div>
          El presente Acuerdo se regirá e interpretará de acuerdo con las leyes de Suiza, excluyendo la Convención de las Naciones Unidas sobre los Contratos de Compraventa Internacional de Mercaderías de 11.4.1980 (Convención de las Naciones Unidas sobre la Compraventa).
        </div>
        <div>
          El lugar de jurisdicción para todas las disputas entre PXL y sus usuarios es Zúrich.
        </div>
        <div>
          Sin embargo, PXL también podrá interponer una acción contra el usuario en su domicilio o lugar de residencia.
        </div>
        <div>
          PXL puede transferir sus derechos y obligaciones bajo el contrato en su totalidad o en parte a un tercero si el cumplimiento del contrato no se ve perjudicado o puesto en peligro por ello y no se oponen intereses legítimos superiores del usuario.
        </div>
        <div>
          Estas condiciones de uso se aplicarán en su versión actual en el momento del uso respectivo de la aplicación web.
        </div>
        <div>
          El uso de la forma masculina para los usuarios en este documento incluye expresamente a las mujeres y a los usuarios varios.
        </div>
        <div>
          Si alguna de las disposiciones de este acuerdo es o llega a ser inválida, se sustituirá por una disposición que se acerque lo más posible al objetivo económico de la disposición inválida. La validez de las restantes disposiciones no se verá afectada por ello.
        </div>
      </li>
      <li>
        <span class="title">Póngase en contacto con PXL:</span>
        <p>Correo electrónico:
          <a href="mailto:info@pxl-vision.com">info@pxl-vision.com</a> o
          <a href="mailto:support@pxl-vision.com">support@pxl-vision.com</a>
        </p>
        <p>Dirección: PXL Vision AG, Mühlebachstrasse 164, CH-8008 Zurich, Switzerland</p>
        <p>Teléfono: +41 44 295 10 40</p>
        <p>Formulario de contacto: <a href="https://pxl-vision.com/contactus/">https://pxl-vision.com/contactus/</a></p>
      </li>
    </ol>
    <p class="last-update">© PXL Vision AG, Estado 09/2020</p>
  </div>`,
  privacyPolicy: `
    <style>
    .terms-subtitle {
      font-size: 1.1rem;
      font-weight: bold;
    }
    .tac-content {
      margin-top: 4em;
      font-size: 0.8rem;
    }
    .tac-content a {
      color: #e9425e;
      margin-top: 30px;
    }
    .tac-content div {
      margin-bottom: 10px;
    }
    .tac-content > ol> li > div:nth-child(2) {
      margin-top: 10px;
    }
    .tac-content ol {
      list-style-position: inside;
      padding-inline-start: unset;
      counter-reset: item;
      margin-block-start: 5px;
      text-align: justify;
      list-style-type: upper-roman;
    }
    .tac-content ol li::marker {
      font-weight: bold;
    }
    .tac-content ol.b {
      list-style-type: lower-alpha;
    }
    .tac-content ol.b > li,
    .tac-content ol.d > li,
    .tac-content ol.k > li {
      margin-left: 10px;
    }
    .tac-content .lower-alpha {
      list-style-type: lower-alpha;
    }
    .tac-content .dash {
      list-style-type: none;
      padding: 0;
    }
    .tac-content .dash li {
      margin: 3px 0 3px 10px;
    }
    .tac-content .dash li::before {
      content: "- ";
      font-weight: bold;
    }
    .tac-content ol.d {
      list-style-type: decimal;
    }
    .tac-content ol.d .subheading {
      font-weight: bold;
    }
    .tac-content ol.d > li p {
      margin: 3px 0 3px 10px;
    }
    .tac-content .no-bullets {
      list-style-type: none;
      padding: 0;
    }
    .tac-content .no-bullets > li {
      padding: 3px 0 3px 10px;
    }
    .tac-content ol.k {
      list-style-type: none;
    }
    .tac-content ol.k li {
      margin-left: 10px;
    }
    .tac-content ol.k subheading {
      font-weight: bold;
    }
    .tac-content div {
      padding-left: 10px;
    }
    .tac-content.m > li {
      margin-bottom: 10px;
    }
    .tac-content ol.k > li:before {
      font-weight: bold;
      content: "(" counter(item) ") ";
      counter-increment: item;
    }
    .tac-content ol.a > li::marker {
      font-weight: bold;
      font-size: 1.1rem;
    }
    .tac-content .title {
      font-weight: bold;
      font-size: 1.1rem;
    }
    ol.b > span {
      margin-bottom: 10px;
    }
    .tac-content > ol > li {
      margin-bottom: 30px;
    }
    </style>
      <div class="tac-content">
      <div class="terms-subtitle">
        <p>
          Acuerdo de usuario de PXL Vision AG para los usuarios finales de la aplicación web "Daego".
        </p>
      </div>
      <ol class="a">
        <li>
          <span class="title">Descripción del servicio</span>
          <div>
            PXL Vision AG (en adelante, "PXL", "nosotros" o "nuestro") le ofrece la WebApp "Daego" (en adelante, el "Producto"), una solución de portal para utilizar en su dispositivo terminal compatible, así como servicios de servidor, a través de la cual puede escanear su documento de identidad emitido por un organismo oficial (por ejemplo, pasaporte, tarjeta de identidad para extranjeros, etc.) y que luego compara los datos escaneados con un selfie creado a través de la WebApp.A continuación, el servidor compara los datos escaneados con un selfie creado a través de la aplicación web y, si tiene éxito, genera una prueba digital de identidad para utilizarla con los socios de la transacción.
          </div>
          <div>
            La prueba de identidad digital le permite llevar a cabo una transacción con los proveedores correspondientes (por ejemplo, proveedores de telefonía móvil, bancos, tiendas web, etc. - en lo sucesivo denominados "socios de la transacción").
          </div>
          <div>
            Para llevar a cabo una transacción, un socio de la transacción le pedirá (por ejemplo, al celebrar un contrato en línea) que haga clic en un enlace a la aplicación web proporcionada por el socio con un código de transacción o, después de entrar directamente en la dirección web de la aplicación web y escanear los documentos descritos a continuación, que introduzca un código de transacción proporcionado por el socio o que escanee un código QR.
          </div>
          <div>
            La WebApp le identifica como titular del documento oficial de identidad escaneado durante la transacción. Para la verificación por parte del socio de la transacción, los datos recogidos se transmiten adicionalmente al socio de la transacción cuando ésta se inicia, quien los almacena de acuerdo con sus requisitos de protección de datos.
          </div>
          <div>
            La verificación de su documento de identidad con el selfie tomado se realiza de la siguiente manera:
            <ol class="b">
              <li>
                Al enfocar el documento de identidad con la cámara del dispositivo final y activarlo, la aplicación web fotografía y escanea su documento de identidad e identifica los datos personales que contiene mediante el reconocimiento de texto.
              </li>
              <li>
                Además, se captura la foto completa del documento de identidad con los elementos de seguridad adicionales (como el holograma y los elementos de diseño).
              </li>
              <li>
                Después de escanear el documento, se le pedirá que grabe un breve vídeo suyo con la cámara conectada al terminal.
              </li>
              <li>
                Los datos, fotos y vídeos recogidos por la WebApp se transmiten desde su dispositivo final a través de una conexión segura en tiempo real a los servidores con certificación SOC 2 situados en Suiza, donde se procesan y almacenan. En los sistemas de PXL, los datos recogidos se comparan entre sí y la foto recogida se coteja con el vídeo selfie en relación con los datos biométricos. Además, se verifica la foto del documento de identidad. Una vez finalizada la verificación, se le solicita su consentimiento para la transmisión de los datos de identificación y los archivos de imagen recopilados al socio de la transacción y, si da su consentimiento, los datos se transmiten al socio de la transacción que le proporcionó el enlace a la WebApp o la identificación de la transacción o el código de barras para llevar a cabo el proceso de identificación, para la confirmación de la identidad y, si procede, para el almacenamiento en su cuenta de cliente allí.
              </li>
              <li>
                El uso contractual de la WebApp y el procedimiento de identificación son gratuitos para usted.
              </li>
              <li>
                El uso de la WebApp en un teléfono móvil puede dar lugar a conexiones de datos de telefonía móvil, cuyo cálculo o volumen de cobro depende de la tarifa de datos de su operador de red.
              </li>
            </ol>
          </div>
        </li>
        <li>
          <span class="title">Condiciones de uso</span>
          <div>
            El requisito previo para utilizar la funcionalidad de identificación y verificación de la WebApp es el suministro de un código de transacción o un enlace o código QR por parte de un socio de la transacción.
          </div>
          <div>
            La WebApp no está asociada a la creación de una cuenta de usuario permanente con PXL, la verificación sólo se realiza una vez para el código de transacción asignado. Si se identifica de nuevo para otro servicio a través de la WebApp, el proceso de escaneo y verificación debe repetirse.
          </div>
          <div>
            A menos que usted permita explícitamente que PXL utilice los datos de identificación recogidos para fines de desarrollo científico y mejora de los procesos de aprendizaje automático subyacentes al procedimiento de identificación, sus datos de identificación se eliminarán después de la transmisión al socio de la transacción. El socio de la transacción almacena estos datos de acuerdo con sus directrices de protección de datos, de las que debe tomar nota por separado.
          </div>
          <div>
            Si no está totalmente de acuerdo con estas condiciones contractuales, no continúe con el proceso de identificación y pregunte al socio de la transacción por otras opciones de identificación.
          </div>
        </li>
        <li>
          <span class="title">Derechos de video selfie</span>
          <div>
            Para poder utilizar el producto, es necesario que proporciones tus datos personales y el vídeo selfie que has creado. Por la presente, usted concede a PXL el derecho a utilizar el vídeo que ha creado, de forma gratuita y por un período ilimitado de tiempo y lugar, con el fin de crear y mantener la identidad digital, para llevar a cabo el proceso de verificación y mejorar los procesos técnicos (siempre que haya dado su consentimiento) y renunciar a sus derechos sobre su propia imagen en el ámbito del tratamiento necesario de los datos de la imagen. Puede revocar este consentimiento en cualquier momento enviando un correo electrónico a
            <a href="mailto:privacy@pxl-vision.com">privacy@pxl-vision.com</a>.
          </div>
        </li>
        <li>
          <span class="title">Renuncia a la garantía</span>
          <div>
            La aplicación web que se proporciona de forma gratuita y los servicios asociados se ofrecen "tal y como se entregan", es decir, con todos los errores existentes y con exclusión de cualquier tipo de garantías o promesas. PXL por la presente renuncia a todas las garantías de cualquier tipo.
          </div>
          <div>
            La compatibilidad con los sistemas operativos y dispositivos finales utilizados por el usuario no está garantizada en ningún momento.
          </div>
          <div>
            Por lo tanto, el uso de la app web para verificar la identidad es por cuenta y riesgo del usuario, y tampoco se garantiza el éxito de su uso para iniciar una transacción a través de la app. 
          </div>
        </li>
        <li>
          <span class="title">Responsabilidad de PXL</span>
          <div>
            PXL sólo es responsable de los daños si se basan en un incumplimiento de una obligación contractual material o en una conducta intencional o gravemente negligente por parte de PXL, sus representantes legales o agentes indirectos. Si se incumple una obligación contractual esencial por negligencia leve, la responsabilidad de PXL se limita a los daños previsibles típicos del contrato. Una obligación contractual esencial se da en el caso de las obligaciones cuyo cumplimiento hace posible la correcta ejecución del contrato en primer lugar o en cuya observancia el cliente ha confiado y podría haber confiado.
          </div>
          <div>
            Queda excluida cualquier otra responsabilidad, incluida la responsabilidad por daños indirectos, daños consecuenciales y pérdida de beneficios.
          </div>
        </li>
        <li>
          <span class="title">Protección de datos</span>
          <div>
            PXL se compromete a proteger su privacidad. Nuestro objetivo es hacer que el uso de nuestros productos y servicios sea tan fácil de usar, fiable y seguro como sea posible, protegiendo siempre su información personal como se define en la Política de Privacidad de PXL ("Política de Privacidad"). Para obtener información sobre nuestras prácticas de privacidad y nuestra política de privacidad, consulte la
            <u>Política de Privacidad</u>.
          </div>
          <div>
            Antes de utilizar el proceso de verificación, solicitamos su consentimiento expreso para que PXL pueda recopilar y procesar datos personales, incluidas las categorías especiales de datos personales en el sentido del artículo 9 (1) del Reglamento General de Protección de Datos o el artículo 3 (c) de la Ley de Protección de Datos de Suiza, a través de la WebApp, como los datos biométricos.
          </div>
          <div>
            También recopilamos datos sobre documentos de identificación que la aplicación no pudo leer, fotos y vídeos en los que la verificación humana y la aplicación producen resultados diferentes, y datos sobre verificaciones satisfactorias.
          </div>
          <div>
            Para más información, lea la Política de Privacidad, que por la presente forma parte de su Acuerdo de Usuario.
          </div>
          <div>
            Tenga en cuenta que para la descarga del software a través de las App Stores (por ejemplo, Apple y Google) se aplica una normativa de protección de datos distinta, de la que deberá informarse cuando utilice la App Store. PXL no asume ninguna responsabilidad por el procesamiento de datos de estos proveedores.
          </div>
        </li>
        <li>
          <span class="title">Otras disposiciones.</span>
          <div>
            Este acuerdo se regirá por la legislación suiza, con exclusión de la Convención de las Naciones Unidas sobre los Contratos de Compraventa Internacional de Mercaderías de 11.4.1980 (Convención de las Naciones Unidas sobre la Compraventa).
          </div>
          <div>
            El lugar de jurisdicción para todas las disputas entre PXL y sus usuarios es Zúrich.
          </div>
          <div>
            Sin embargo, PXL también podrá perseguir al usuario en su domicilio o lugar de residencia.
          </div>
          <div>
            PXL puede transferir sus derechos y obligaciones bajo el contrato en su totalidad o en parte a un tercero si el cumplimiento del contrato no se ve perjudicado o puesto en peligro por ello y no se oponen intereses legítimos superiores del usuario. 
          </div>
          <div>
            Estas condiciones de uso se aplicarán en su versión actual en el momento del uso respectivo de la aplicación web. 
          </div>
          <div>
            El uso de la forma masculina para los usuarios en este documento incluye explícitamente a los usuarios femeninos y diversos. 
          </div>
          <div>
            Si alguna de las disposiciones de este acuerdo es o llega a ser inválida, se sustituirá por una disposición que se acerque lo más posible al objetivo económico de la disposición inválida. La validez de las restantes disposiciones no se verá afectada por ello.
          </div>
        </li>
        <li>
          <span class="title">Póngase en contacto con PXL:</span>
          <p>Correo electrónico:
            <a href="mailto:info@pxl-vision.com">info@pxl-vision.com</a> o
            <a href="mailto:support@pxl-vision.com">support@pxl-vision.com</a>
          </p>
          <p>Dirección: PXL Vision AG, Mühlebachstrasse 164, CH-8008 Zurich, Switzerland</p>
          <p>Formulario de contacto: <a href="https://pxl-vision.com/contactus/">https://pxl-vision.com/contactus/</a></p>
        </li>
        <p>© PXL Vision AG, Estado 09/2020</p>
      </ol>
    </div>`,
};
