const SUCCESS_ERROR_CODES: number[] = [0];

const SECOND_PAGE_ERROR_CODES: number[] = [227, 228];

const SECOND_PAGE_ERROR_CODES_FOR_SDK3: number[] = [228];

const ERROR_CODES_ALWAYS_BLOCKING: number[] = [300, 301, 302, 303, 304, 305, 306];

const CODES_TO_SKIP_MANUAL_CAPTURE: number[] = [204, 205];

const CODES_TO_SKIP_MANUAL_CAPTURE_FOR_SDK3: number[] = [204, 205, 222, 211];

const AVAILABLE_ERROR_CODES: number[] = [
  0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 100, 101, 102, 103, 200, 201, 203, 204, 205, 207, 208, 209,
  210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228,
  230, 240, 300, 301, 302, 303, 304, 305, 306, 308, 400, 500, 503, 504, 506, 505, 502, 600, 666,
  700, 701, 800, 801, 803, 900, 901, 903, 904, 905, 906, 1000, 1001, 1100, 2000,
];

const ERROR_CODES_WITH_DESCRIPTION: any = {
  _0: 'None',
  _3: 'ThirdPartyLibFailure',
  _8: 'FunctionNotIncluded',
  _10: 'IncorrectInput',
  _200: 'IdGeneric',
  _201: 'IdTimeoutExpired',
  _203: 'IdNoData',
  _204: 'IdNotInList',
  _205: 'IdExpired',
  _207: 'IdFaceImageCaptureFailed',
  _208: 'IdTypeNotValid',
  _209: 'IdPageMissing',
  _211: 'IdMatchingFailed',
  _212: 'IdBadMrzFields',
  _213: 'IdBadMrzFieldBirthDay',
  _214: 'IdBadMrzFieldCompositCheckDigit',
  _215: 'IdBadMrzFieldExpiryDay',
  _216: 'IdBadMrzFieldDocumentNumber',
  _217: 'IdBadMrzFieldGender',
  _218: 'IdBadMrzFieldFirstName',
  _219: 'IdBadMrzFieldLastName',
  _220: 'IdBadMrzFieldCountry',
  _221: 'IdBadMrzFieldNationality',
  _222: 'IdIncompleteData', // MRZ/VIZ extracted data is not complete
  _226: 'IdPageMissing',
  _227: 'IdNeedSecondPageForMrz',
  _230: 'IdNotDetected',
  _240: 'MrzNotDetected',
  _228: 'IdNeedSecondPageForMatching',
  _300: 'ImageGeneric',
  _301: 'ImageDocumentTooRotated',
  _302: 'ImageDocumentTooFar',
  _303: 'ImageDocumentTooClose',
  _304: 'BlurryImage',
  _305: 'ImageUnsupportedType',
  _306: 'ImageUnsupportedResolution',
  _500: 'FaceCaptureGeneric',
  _503: 'FaceNotRecognised',
  _504: 'FaceNotVerified',
  _666: 'FunctionNotImplemented',
  _999: 'NoImages',
  _1100: 'FaceLivenessGeneric',
  _1101: 'FaceLivenessFailed',
  _9999: 'Unknown',
};

export {
  SUCCESS_ERROR_CODES,
  SECOND_PAGE_ERROR_CODES,
  SECOND_PAGE_ERROR_CODES_FOR_SDK3,
  ERROR_CODES_ALWAYS_BLOCKING,
  AVAILABLE_ERROR_CODES,
  ERROR_CODES_WITH_DESCRIPTION,
  CODES_TO_SKIP_MANUAL_CAPTURE,
  CODES_TO_SKIP_MANUAL_CAPTURE_FOR_SDK3,
};
